<template>
  <div id="sorry">
    <h1>Upgrade Unavailable</h1>

    <p>We are unable to offer subscriptions in your country at this time, but we are working on it!</p>

    <p>To learn more, please visit <span><a href="https://aira.io/global">https://aira.io/global</a></span>.</p>
  </div>
</template>

<script>
export default {
  name: 'Sorry',
};
</script>

<style lang="scss">
@import '../../styles/theme/_mobile.scss';

#sorry {
  margin: 20px;
}
</style>
